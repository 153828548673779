@import "main.css";
a.ctaLinks-button { font-size: 2em !important;
letter-spacing: 2px !important;
display: inline-block !important;
}



.accompagnement_text > div {
margin-top: 0px!important;
margin-bottom: 0px!important;
}

.bi-telephone { display : none; }

.px-2.px-lg-4.py-2.bg-sixthColor {
    display: none;
}
